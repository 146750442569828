import { Card, Stack, styled, Typography } from "@mui/material";
import { FeatureFlags } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BannerLayout from "../../components/BannerLayout";
import { useClientHasFeature } from "../../domain/client";
import { translationKeys } from "../../translations/main-translations";
import MeasureInfoCard from "../measure/MeasureInfoCard";
import MethodActivitiesGenerator from "./lab-areas/MethodActivitiesGenerator";
import MethodSuggestions from "./lab-areas/MethodSuggestions";
import SimpleSuggestions from "./lab-areas/SimpleSuggestions";

const Header = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const Content = styled("div")(({ theme }) => ({
    overflowX: "hidden",
    height: "100%",
}));

const LabsView = () => {
    const { t: translate } = useTranslation();
    const [rateLimitActive, setRateLimitActive] = useState(false);

    const hasOpenAIApiEnabled = useClientHasFeature(FeatureFlags.FEATURE_OPENAI_API);

    const activateRateLimit = () => {
        setRateLimitActive(true);
        setTimeout(() => {
            setRateLimitActive(false);
        }, 25000); // Limit to 25s for now (Backend 20s)
    };

    return (
        <BannerLayout
            banner={
                <>
                    <Header sx={{ px: { xs: 2, md: 8 }, py: 1.75 }}>
                        <Typography variant="h5" noWrap>
                            Labs 👩‍🔬🧫
                        </Typography>
                    </Header>
                </>
            }
        >
            <Content sx={{ px: { xs: 2, md: 8 }, py: { xs: 2, md: 4 } }}>
                {hasOpenAIApiEnabled ? (
                    <Stack sx={{ height: "100%" }} spacing={2}>
                        <MeasureInfoCard severity="info" title="">
                            {translate(translationKeys.VDLANG_OPENAI_MEASURE_HINT)}
                        </MeasureInfoCard>
                        <Stack direction="row" display="flex" justifyContent="space-between" spacing={2}>
                            <MethodSuggestions rateLimitActive={rateLimitActive} activateRateLimit={activateRateLimit} />
                            <SimpleSuggestions rateLimitActive={rateLimitActive} activateRateLimit={activateRateLimit} />
                        </Stack>
                        <Stack direction="row" display="flex" justifyContent="space-between" spacing={2}>
                            <MethodActivitiesGenerator rateLimitActive={rateLimitActive} activateRateLimit={activateRateLimit} />
                            <Card sx={{ width: "100%" }}>&nbsp;{/* free space */}</Card>
                        </Stack>
                    </Stack>
                ) : null}
            </Content>
        </BannerLayout>
    );
};

export default React.memo(LabsView);
