import { Link as StandardLink, Typography } from "@mui/material";
import { CostLeverDto, MessageListDto, MethodDetailDto } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateIdeaMutation } from "../../domain/ideas";
import { useMeasureConfigs } from "../../domain/measure-config";
import { useCreateMeasure } from "../../domain/measure/detail";
import useDialog from "../../hooks/useDialog";
import { translationKeys } from "../../translations/main-translations";
import CallToActionDialog from "../navbar/CallToActionDialog";

interface SmartLabListProps {
    messages: MessageListDto;
    method?: CostLeverDto | MethodDetailDto;
    methodTitle?: string;
    showCreateProcess?: boolean;
}

const SmartLabList = ({ messages, method, methodTitle, showCreateProcess }: SmartLabListProps) => {
    const { t: translate } = useTranslation();

    const measureConfigs = useMeasureConfigs();
    const navigate = useNavigate();
    const createMeasureMutation = useCreateMeasure(({ id }) => navigate(`/measure/${id}`));
    const createMeasureDialog = useDialog();
    const createIdea = useCreateIdeaMutation(({ id }) => navigate(`/opportunities/${id}`));

    const [initialMeasureTitle, setInitialMeasureTitle] = useState("");

    const openDialog = (title: string) => {
        setInitialMeasureTitle(title);
        createMeasureDialog.open();
    };

    return messages.messageList === undefined ? (
        <Typography sx={{ marginTop: 4 }}>{messages.message}</Typography>
    ) : (
        <>
            <CallToActionDialog
                show={createMeasureDialog.isOpen}
                onHide={createMeasureDialog.close}
                translate={translate}
                createMeasure={createMeasureMutation.mutate}
                createIdea={createIdea.mutate}
                method={method}
                methodTitle={methodTitle}
                measureConfigs={measureConfigs}
                initialMeasureTitle={initialMeasureTitle}
                key={`${methodTitle ?? ""}-${initialMeasureTitle}`}
                userCanCreateProcess={true}
                userHasIdeaAccess={false}
            />
            <Typography>
                <ol>
                    {messages.messageList.map((msg, index) => (
                        <li key={index}>
                            {msg}&nbsp;
                            {showCreateProcess === undefined || showCreateProcess === true ? (
                                <StandardLink onClick={() => openDialog(msg)}>
                                    {translate(translationKeys.VDLANG_METHODS_DETAIL_START_A_PROCESS)}
                                </StandardLink>
                            ) : null}
                        </li>
                    ))}
                </ol>
            </Typography>
        </>
    );
};

export default React.memo(SmartLabList);
