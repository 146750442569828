import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import LoadingButton from "../../../components/LoadingButton";
import Select from "../../../components/input/select/Select";
import { useSimpleSuggestions } from "../../../domain/labs";
import { useMethodsQuery } from "../../../domain/methods/methods";
import { translationKeys } from "../../../translations/main-translations";
import SmartLabList from "../SmartLabList";

interface MethodSuggestionsProps {
    rateLimitActive: boolean;
    activateRateLimit: () => void;
}

const MethodSuggestions = ({ rateLimitActive, activateRateLimit }: MethodSuggestionsProps) => {
    const { t: translate } = useTranslation();
    const methodsQuery = useMethodsQuery();
    const [currentMethod, setCurrentMethod] = useState<string | null>("CO-1");
    const simpleSuggestionsMutation = useSimpleSuggestions();

    const methodsOptions = useMemo(() => {
        if (methodsQuery.data === undefined) {
            return [];
        }

        return methodsQuery.data
            .map((method) => ({
                value: method.code,
                label: `${method.code} - ${method.nameDe}`,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
    }, [methodsQuery.data]);

    const fetchSuggestions = () => {
        if (methodsQuery.data === undefined) {
            return;
        }
        const method = methodsQuery.data.find((s) => s.code === currentMethod);
        if (method === undefined) {
            return;
        }

        // As of now those are simple prompts for experiments. If this becomes advanced move it as "secret" business logic in the BE.
        simpleSuggestionsMutation.mutate({
            message: `Generiere Maßnahmen für die Methode "${method.nameDe}". Diese ist beschrieben als: ${method.hintDe}`,
            temperature: 0,
        });
        activateRateLimit();
    };

    const changeMethodSelection = (option: SingleValue<{ value: string; label: string }>) => {
        setCurrentMethod(option?.value ?? null);
        simpleSuggestionsMutation.reset();
    };

    const currentMethodTitle = methodsQuery.data?.find((s) => s.code === currentMethod)?.nameDe;
    const currentMethodDto = methodsQuery.data?.find((s) => s.code === currentMethod);

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader title="Vorschläge für Methoden" />
            <CardContent>
                <Select
                    isSearchable
                    label={translate(translationKeys.VDLANG_METHOD_TABLE_NAME_COLUMN_LABEL)}
                    options={methodsOptions}
                    value={methodsOptions.find((v) => v.value === currentMethod)}
                    onChange={changeMethodSelection}
                    menuPortalTarget={document.body}
                />
                <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={fetchSuggestions}
                    startIcon={<AutoFixHighIcon />}
                    isLoading={simpleSuggestionsMutation.isLoading || rateLimitActive}
                >
                    {translate(translationKeys.VDLANG_MEASURE_TABS_SUGGESTIONS)}
                </LoadingButton>
                {simpleSuggestionsMutation.isSuccess ? (
                    <SmartLabList messages={simpleSuggestionsMutation.data} method={currentMethodDto} methodTitle={currentMethodTitle} />
                ) : null}
            </CardContent>
        </Card>
    );
};

export default React.memo(MethodSuggestions);
