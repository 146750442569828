import { useMutation } from "@tanstack/react-query";
import { MessageListDto, SimpleSuggestionsDto } from "api-shared";
import { apiPost } from "../lib/api";

export const useSimpleList = () => {
    return useMutation({
        mutationFn: (data: SimpleSuggestionsDto) => apiPost<MessageListDto>("/labs/simple-list", data),
    });
};

export const useSimpleSuggestions = () => {
    return useMutation({
        mutationFn: (data: SimpleSuggestionsDto) => apiPost<MessageListDto>("/labs/simple-suggestions", data),
    });
};
