import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { Button, Card, CardContent, CardHeader, FormControl, FormGroup, FormLabel, Slider, TextField } from "@mui/material";
import { zSimpleSuggestionsDto } from "api-shared";
import { sample } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import LoadingButton from "../../../components/LoadingButton";
import { useSimpleSuggestions } from "../../../domain/labs";
import { translationKeys } from "../../../translations/main-translations";
import SmartLabList from "../SmartLabList";

interface SimpleSuggestionsProps {
    rateLimitActive: boolean;
    activateRateLimit: () => void;
}

const SimpleSuggestions = ({ rateLimitActive, activateRateLimit }: SimpleSuggestionsProps) => {
    const { t: translate } = useTranslation();
    const [inputText, setInputText] = useState("zum Einsparen von CO2 in der Reifenherstellung.");
    const [temperature, setTemperature] = useState(0);
    const simpleSuggestionsMutation = useSimpleSuggestions();

    // As of now those are simple prompts for experiments. If this becomes advanced move it as "secret" business logic in the BE.
    const fetchSuggestions = () => {
        simpleSuggestionsMutation.mutate({ message: `Erstelle Maßnahmen ${inputText}`, temperature: temperature });
        activateRateLimit();
    };

    const isValidText = zSimpleSuggestionsDto.safeParse({ message: `Erstelle Maßnahmen ${inputText}`, temperature: temperature }).success;

    const generateIdeas = () => {
        const ideas = [
            "zum verbessern der Abläufe im Hochregallager.",
            "zur Verringerung der Kosten für den Fuhrpark in einem produzierenden Gewerbe.",
        ];
        setInputText(sample(ideas) ?? "-");
    };

    return (
        <Card sx={{ width: "100%" }}>
            <CardHeader title="Vorschläge generieren" />
            <CardContent>
                <Form onSubmit={fetchSuggestions} disabled={rateLimitActive}>
                    <FormGroup>
                        <TextField
                            margin="normal"
                            value={inputText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputText(event.target.value)}
                            error={!isValidText}
                            label='Vervollständige den Satz: "Erstelle Maßnahmen ..."'
                        />
                    </FormGroup>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={generateIdeas}
                        startIcon={<LightbulbIcon />}
                        sx={{ marginRight: 4 }}
                    >
                        Ideen
                    </Button>
                    <FormGroup>
                        <FormControl margin="normal">
                            <FormLabel htmlFor="tempField">Varianz</FormLabel>
                            <Slider
                                id="tempField"
                                size="small"
                                onChange={(_, value) => setTemperature(Array.isArray(value) ? value[0] : value)}
                                min={0}
                                step={0.1}
                                max={1.4 /* Higher up to 2.0 is just stupid */}
                                valueLabelDisplay="auto"
                                value={temperature}
                                marks
                            />
                        </FormControl>
                    </FormGroup>
                </Form>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!isValidText}
                    onClick={fetchSuggestions}
                    startIcon={<AutoFixHighIcon />}
                    isLoading={simpleSuggestionsMutation.isLoading || rateLimitActive}
                >
                    {translate(translationKeys.VDLANG_MEASURE_TABS_SUGGESTIONS)}
                </LoadingButton>
                {simpleSuggestionsMutation.isSuccess ? <SmartLabList messages={simpleSuggestionsMutation.data} /> : null}
            </CardContent>
        </Card>
    );
};

export default React.memo(SimpleSuggestions);
